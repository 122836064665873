import { Slot, Slottable } from '@radix-ui/react-slot';
import { clsx } from 'clsx';
import {
  cloneElement,
  ComponentPropsWithoutRef,
  forwardRef,
  isValidElement,
  PropsWithChildren,
} from 'react';
import { Spinner } from '../spinner/spinner.tsx';
import { VisuallyHidden } from '../visually-hidden/visually-hidden.tsx';
import {
  button,
  buttonContent,
  buttonRounded,
  buttonSize,
  buttonSpinner,
  ButtonVariant,
  buttonVariants,
} from './button.css';

export type { ButtonVariant };

export type ButtonProps = {
  inversed?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: ButtonVariant;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  asChild?: boolean;
  loading?: boolean;
  rounded?: boolean;
} & ComponentPropsWithoutRef<'button'>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function ButtonComponent(
    {
      size = 'medium',
      variant = 'primary',
      className,
      children,
      inversed,
      startIcon,
      endIcon,
      asChild = false,
      loading = false,
      rounded = false,
      disabled,
      type = 'button',
      ...rest
    },
    ref,
  ) {
    const Component = asChild ? Slot : 'button';
    const childrenContent =
      asChild && isValidElement<PropsWithChildren>(children)
        ? children.props.children
        : children;

    const content = (
      <>
        {startIcon}
        <Slottable>{childrenContent}</Slottable>
        {endIcon}
      </>
    );

    const ButtonContent = loading ? (
      /**
       * We need a wrapper to set `visibility: hidden` to hide the button content whilst we show the `Spinner`.
       * The button is a flex container with a `gap`, so we use `display: contents` to ensure the correct flex layout.
       *
       * However, `display: contents` removes the content from the accessibility tree in some browsers,
       * so we force remove it with `aria-hidden` and re-add it in the tree with `VisuallyHidden`
       */
      <>
        <div
          className={buttonContent}
          style={{ visibility: loading ? 'hidden' : 'visible' }}
          aria-hidden
        >
          {content}
        </div>
        <VisuallyHidden>{content}</VisuallyHidden>
        <Spinner className={buttonSpinner} size={size === 'small' ? 16 : 20} />
      </>
    ) : (
      content
    );

    return (
      <Component
        ref={ref}
        data-inversed={inversed ? '' : undefined}
        className={clsx(
          button,
          buttonSize[size],
          buttonVariants[variant],
          rounded && buttonRounded,
          className,
        )}
        disabled={disabled || loading}
        type={type}
        {...rest}
      >
        {/**
         * https://github.com/radix-ui/primitives/issues/1825
         * Slot does not work with wrapper around content, so we need to use cloneElement()
         */}
        {asChild && isValidElement(children)
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            cloneElement(children, children.props as any, ButtonContent)
          : ButtonContent}
      </Component>
    );
  },
);
